import React, { useState, useRef } from "react";
import imageCompression from "browser-image-compression";

const ProductList = ({ products, onRemove, onEdit }) => {
  const [editingProduct, setEditingProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [productToRemove, setProductToRemove] = useState(null);
  const [warningPosition, setWarningPosition] = useState({ top: 0, left: 0 });
  const [addAmount, setAddAmount] = useState("");
  const [subtractAmount, setSubtractAmount] = useState("");
  const [showAddInput, setShowAddInput] = useState(false);
  const [showSubtractInput, setShowSubtractInput] = useState(false);

  const handleAddQuantity = (amount) => {
    if (!isNaN(amount) && amount > 0) {
      setEditedProduct((prev) => ({
        ...prev,
        quantity: prev.quantity + amount,
      }));
      setAddAmount(0);
      setShowAddInput(false); // Hide the input after adding
    }
  };

  const handleSubtractQuantity = (amount) => {
    if (!isNaN(amount) && amount > 0 && editedProduct.quantity - amount >= 0) {
      setEditedProduct((prev) => ({
        ...prev,
        quantity: prev.quantity - amount,
      }));
      setSubtractAmount(0);
      setShowSubtractInput(false); // Hide the input after subtracting
    }
  };

  // Ref to store the product container
  const productRefs = useRef({});

  const handleEdit = (product) => {
    setEditingProduct(product._id);
    setEditedProduct(product);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.07, // Set max size to 70KB
          maxWidthOrHeight: 1920, // Optional: resize image to this width or height
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setEditedProduct((prevProduct) => ({
            ...prevProduct,
            image: reader.result,
          }));
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Image compression failed: ", error);
      }
    }
  };

  const handleSave = () => {
    if (editedProduct) {
      const userName = sessionStorage.getItem("userName") || "";

      const currentDateTime = new Date().toLocaleString();

      const updatedProduct = {
        ...editedProduct,
        updatedBy: `${userName} - ${currentDateTime}`,
      };
      
      onEdit(updatedProduct);
      setEditingProduct(null);
      setEditedProduct(null);
    }
  };

  const handleCancel = () => {
    setEditingProduct(null);
    setEditedProduct(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRemoveClick = (product, event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setProductToRemove(product);
    setWarningPosition({
      top: rect.bottom + window.scrollY - 360,
      left: rect.left,
    });
    setShowWarning(true);
  };

  const handleConfirmRemove = () => {
    if (productToRemove) {
      onRemove(productToRemove._id);
      setProductToRemove(null);
    }
    setShowWarning(false);
  };

  const handleCancelRemove = () => {
    setProductToRemove(null);
    setShowWarning(false);
  };

  return (
    <div className="relative flex flex-wrap justify-center mb-4">
      {showWarning && productToRemove && (
        <div
          className="absolute bg-red-300 border border-red-500 rounded p-4"
          style={{
            top: warningPosition.top,
            left: warningPosition.left,
            width: "300px",
          }}
        >
          <p className="text-white-800 padding-bottom-15">
            Are you sure you want to remove {productToRemove.name}?
          </p>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={handleConfirmRemove}
          >
            Yes, Remove
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleCancelRemove}
          >
            Cancel
          </button>
        </div>
      )}
      <div className="w-full p-4">
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="border p-2 mb-4 w-full"
        />
      </div>

      {filteredProducts
        .sort((a, b) => b.quantity - a.quantity)
        .map((product) => (
          <div
            key={product._id}
            className="w-full md:w-1/2 xl:w-1/3 p-4"
            ref={(el) => (productRefs.current[product._id] = el)}
          >
            <div className="bg-white rounded shadow-md p-4">
              <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-4 mb-2">
                <h2 className="text-lg font-bold text-center">
                  {product.name}
                </h2>
              </div>
              {editingProduct === product._id ? (
                <>
                  <p>Image</p>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="border p-2 mb-2 w-full"
                  />
                  {editedProduct.image && (
                    <img
                      src={editedProduct.image}
                      alt={editedProduct.name}
                      className="w-full h-64 object-cover mb-2"
                    />
                  )}

                  <p>Name</p>
                  <input
                    type="text"
                    name="name"
                    value={editedProduct.name}
                    onChange={handleChange}
                    className="border p-2 mb-2 w-full"
                  />

                  <p>Quantity</p>
                  <div className="flex items-center mb-2">
                    <button
                      className="bg-green-500 hover:bg-green-600 text-black font-bold py-1 px-2 rounded"
                      onClick={() => {
                        setShowAddInput(true);
                        setShowSubtractInput(false); // Close subtract input
                      }}
                    >
                      +
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 text-black font-bold py-1 px-2 rounded mx-2"
                      onClick={() => {
                        setShowSubtractInput(true);
                        setShowAddInput(false); // Close add input
                      }}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      name="quantity"
                      value={editedProduct.quantity}
                      onChange={handleChange}
                      className="border p-2 w-full ml-4"
                    />
                  </div>

                  {showAddInput && (
                    <div className="mb-2">
                      <input
                        type="number"
                        placeholder="Enter amount to add"
                        value={addAmount}
                        onChange={(e) => setAddAmount(Number(e.target.value))}
                        className="border p-2 w-full"
                      />
                      <button
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded mt-2"
                        onClick={() => handleAddQuantity(addAmount)}
                      >
                        Add to Quantity
                      </button>
                    </div>
                  )}

                  {showSubtractInput && (
                    <div className="mb-2">
                      <input
                        type="number"
                        placeholder="Enter amount to subtract"
                        value={subtractAmount}
                        onChange={(e) =>
                          setSubtractAmount(Number(e.target.value))
                        }
                        className="border p-2 w-full"
                      />
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded mt-2"
                        onClick={() => handleSubtractQuantity(subtractAmount)}
                      >
                        Subtract from Quantity
                      </button>
                    </div>
                  )}

                  <p>Cost Price</p>
                  <input
                    type="number"
                    name="costPrice"
                    value={editedProduct.costPrice}
                    onChange={handleChange}
                    className="border p-2 mb-2 w-full"
                  />

                  <p>Selling Price</p>
                  <input
                    type="number"
                    name="sellingPrice"
                    value={editedProduct.sellingPrice}
                    onChange={handleChange}
                    className="border p-2 mb-2 w-full"
                  />

                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <div className="flex flex-col max-w-sm rounded overflow-hidden shadow-lg bg-white p-4 h-full">
                  <div className="flex flex-col flex-grow">
                    <p>Quantity: {product.quantity}</p>
                    <p>Cost Price: Rs: {product.costPrice}</p>
                    <p>Selling Price: Rs: {product.sellingPrice}</p>
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-full h-64 object-cover mb-2"
                    />
                  </div>

                  <div className="flex space-x-2 mb-2">
                    <button
                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                      onClick={(event) => handleRemoveClick(product, event)}
                    >
                      Remove
                    </button>
                    <button
                      className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleEdit(product)}
                    >
                      Edit
                    </button>
                  </div>
                  <p className="text-sm text-gray-600 italic mt-auto text-right">
                    Lastedited By: {product.updatedBy}
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProductList;
