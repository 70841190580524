import React, { useState } from "react";
import imageCompression from "browser-image-compression";

const ProductForm = ({ onAdd }) => {
  const [newProduct, setNewProduct] = useState({
    name: "",
    quantity: "",
    costPrice: "",
    sellingPrice: "",
    createdBy: sessionStorage.getItem("userName") || "",
    image: "",
  });

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setNewProduct({ ...newProduct, [id]: value });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.07,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setNewProduct({ ...newProduct, image: reader.result });
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Image compression failed: ", error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userName = sessionStorage.getItem("userName") || "";

    const currentDateTime = new Date().toLocaleString(); 

    const updatedProduct = {
      ...newProduct,
      createdBy: `${userName} - ${currentDateTime}`
    };

    onAdd(updatedProduct);
    setNewProduct({
      name: "",
      quantity: 0,
      costPrice: 0,
      sellingPrice: 0,
      createdBy: sessionStorage.getItem("userName") || "",
      image: "",
    });
    setIsAccordionOpen(false); // Close the accordion after submission
  };

  return (
    <div className="w-full max-w-lg">
      <div style={{ display: "flex"}}>
        <button
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 "
        >
          {isAccordionOpen ? "Close Form" : "+ Add"}
        </button>
      </div>
      {isAccordionOpen && (
        <div className="border border-gray-200 p-4 rounded bg-gray-100">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="image"
                  >
                    Image
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="image"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="name"
                  type="text"
                  value={newProduct.name}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="quantity"
                >
                  Quantity
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="quantity"
                  type="number"
                  value={newProduct.quantity}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="costPrice"
                >
                  Cost Price
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="costPrice"
                  type="number"
                  value={newProduct.costPrice}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="sellingPrice"
                >
                  Selling Price
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="sellingPrice"
                  type="number"
                  value={newProduct.sellingPrice}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Add Product
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ProductForm;
